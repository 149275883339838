/** Layout for public pages - adds the Bottom menu after the content */
import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import React from 'react'
import { FC } from 'react'
import classNames from 'classnames'
import Bottom from '../components/menus/bottom.container'
import Page from '../components/page'
import IndexLayout from './index-layout'

const styles = (theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            alignItems: 'center',
        },
        contentPage: {
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2),
            },
            [theme.breakpoints.up('sm')]: {

                margin: theme.spacing(8),
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
        },
        header: {
            fontSize: 64,
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(2),
            },
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(8),
            },

        },
    })

export interface PublicLayoutProps extends WithStyles<typeof styles> {
    contentPage?: boolean
    frontmatter?: {
        title: string
        consentContext: 'signatu' | 'customer'
    }
}

const PublicLayout: FC<PublicLayoutProps> = ({
    children,
    classes,
    contentPage = true,
    /** Use the Signatu consent context for public pages */
    frontmatter = { title: 'Untitled', consentContext: 'signatu' },
}) => (
        <IndexLayout frontmatter={frontmatter}>
            <Page>
                <div className={classNames(classes.content, { [classes.contentPage]: contentPage })}>
                    {contentPage && (
                        <Typography variant="h1" className={classes.header}>
                            {frontmatter.title}
                        </Typography>
                    )}
                    {children}
                </div>
                <Bottom />
            </Page>
        </IndexLayout>
    )

export default withStyles(styles)(PublicLayout)
