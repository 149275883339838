import { colors, Theme, WithStyles, withStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { fetch } from '@signatu/common-lib'
import React from 'react'

export interface StatusPageProps {
    className?: string
    style?: any
    pageId?: string
    updateDelay: number /** Number of seconds between updates */
}

export enum ServiceStatusState {
    NONE = 'none',
    MINOR = 'minor',
    MAJOR = 'major',
    CRITICAL = 'critical',
}
export interface ServiceStatus {
    description: string
    indicator: ServiceStatusState
}

export interface StatusPageData {
    page: {
        url: string
        id?: string
        name?: string
        updated_at?: string
    }
    status: ServiceStatus
}

export interface StatusPageState {
    updateStarted: boolean
    statusInfo: StatusPageData
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            background: theme.palette.background.default,
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontFamily: theme.typography.button.fontFamily,
            borderRadius: `${theme.spacing(1)}px`,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            '@media (max-width: 768px)': {
                display: 'none',
            },
        },
        light: {
            height: theme.spacing(2),
            width: theme.spacing(2),
            borderRadius: '50%',
        },
        statusText: {
            ...theme.typography.button as any,
            marginLeft: theme.spacing(1),
            fontSize: '10px',
            overflowWrap: 'anywhere',
        },
        none: {
            backgroundColor: colors.green[500],
        },
        major: {
            backgroundColor: colors.orange[500],
        },
        minor: {
            backgroundColor: colors.yellow[500],
        },
        critical: {
            backgroundColor: theme.palette.error.main,
        },
    })

class StatusPage extends React.Component<StatusPageProps & WithStyles<typeof styles>, StatusPageState> {
    static defaultProps = {
        pageId: 'fpd3wxqhsy05',
    }

    componentIsMounted = false

    api = {
        baseUrl: '',
        status: '',
    }

    constructor(props) {
        super(props)
        this.state = {
            updateStarted: false,
            statusInfo: {
                page: {
                    url: 'https://signatu.com',
                },
                status: {
                    description: 'All Systems Operational',
                    indicator: ServiceStatusState.NONE,
                },
            },
        }
    }

    runUpdateLoop = async () => {
        const { updateDelay } = this.props
        const response = await fetch(this.api.status)
        const status: StatusPageData = await response.json()
        if (this.componentIsMounted) {
            this.setState({ statusInfo: status })
            setTimeout(this.runUpdateLoop, (updateDelay || 60) * 1000)
        }
    }

    updateApiEndpoints(props: StatusPageProps) {
        if (!(props.pageId && this.componentIsMounted)) {
            return
        }

        const { updateStarted } = this.state
        this.api.baseUrl = `https://${props.pageId}.statuspage.io/api/v2`
        this.api.status = `${this.api.baseUrl}/status.json`
        if (!updateStarted) {
            this.setState({ updateStarted: true })
            this.runUpdateLoop()
        }
    }

    componentWillUnmount() {
        this.componentIsMounted = false
    }

    componentDidMount() {
        this.componentIsMounted = true
        this.updateApiEndpoints(this.props)
    }

    render() {
        const { classes, className, style } = this.props
        const { statusInfo } = this.state
        return (
            <div className={className || ''} style={style}>
                <a target="_blank" rel="noopener noreferrer" href={statusInfo.page.url} className={classes.root}>
                    <div className={`${classes.light} ${classes[statusInfo.status.indicator]}`} />
                    <div className={classes.statusText}>{statusInfo.status.description}</div>
                </a>
            </div>
        )
    }
}

const styled = withStyles(styles)(StatusPage) 
export { styled as StatusPage }
