import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'

export interface ProductQuoteProps {
    from: string
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        quote: {
            position: 'relative',
            fontStyle: 'italic',
            margin: 0,
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                padding: '32px 32px',
            },
            [theme.breakpoints.up('md')]: {
                padding: '32px 128px',
            },

            '&:before, &:after': {
                position: 'absolute',
                width: '60px',
                height: '60px',
                fontSize: '120px',
                lineHeight: 1,
            },
            '&:before': {
                top: 0,
                left: 0,
                content: "'\u201C'",
            },
            '&:after': {
                top: 0,
                right: 0,
                content: "'\u201D'",
            },
        },
        from: {
            fontWeight: 'normal',
        },
    })

const Quote: FC<ProductQuoteProps & WithStyles<typeof styles>> = (props) => {
    const { classes, children, from } = props

    return (
        <div className={classes.root}>
            <blockquote className={classes.quote}>{children}</blockquote>
            <div className={classes.from}>-- {from}</div>
        </div>
    )
}

export default withStyles(styles)(Quote)
