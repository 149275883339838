import { RootStore } from '@signatu/app'
import React from 'react'
import { connect } from 'react-redux'

import Bottom from './bottom'

const mapStateToProps = (state: RootStore, props) => {
    const { config, account } = state

    return {
        config,
        isLoggedIn: account.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Bottom)
