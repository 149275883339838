import { ButtonColor, PolicyButton } from '@signatu/policy-react'
import React from 'react'

import { StatusPage } from '../components/status-page'
import { Menu } from './menu'

const bottomMenu = ({ activePolicyLink }): Menu[] => [
    {
        name: 'Product',
        link: '',
        loggedIn: false,
        children: [
            {
                name: 'Why Signatu?',
                link: '',
            },
            {
                name: 'Customer Stories',
                link: '/product/stories',
            },
            {
                name: 'Security',
                link: '/product/security',
            },
            {
                name: 'Plans & Pricing',
                link: '/product/plans',
            },
            {
                name: 'Product Updates',
                link: '/product/updates',
            },
            {
                name: 'Signatu Demo',
                link: '/docs/api/v0/sdk/live',
                loggedIn: true,
            },
        ],
    },
    {
        name: 'Platform',
        link: '',
        children: [
            {
                name: 'Technical Docs',
                link: '/docs',
            },
            {
                name: 'Integration',
                link: '/docs/api/v0/integration',
            },
            {
                name: 'SDK',
                link: '/docs/api/v0/sdk',
            },
            {
                name: 'Collaborate',
                link: '/collaborate',
            },
            {
                name: 'Legal Partners',
                link: '/legal/partners',
            },
        ],
    },
    {
        name: 'Support',
        link: '',
        children: [
            {
                name: 'Changelog',
                link: '/changelog',
            },
            {
                name: 'Legal Guides',
                link: '/legal/guides',
                loggedIn: true,
            },
            {
                name: 'Help',
                link: '/help',
            },
            {
                name: 'Whitepapers',
                link: '/whitepapers',
            },
            {
                name: 'Status',
                element: <StatusPage style={{ marginTop: '1em' }} updateDelay={60} />,
            },
        ],
    },
    {
        name: 'Company',
        link: '',
        children: [
            {
                name: 'Contact',
                link: '/company/contact',
            },
            {
                name: 'About Us',
                link: '/company/about',
            },
            {
                name: 'Blog',
                link: '/blog',
            },
            {
                name: 'News',
                link: '/news',
            },
            {
                name: 'Investors',
                link: '/investors',
            },
        ],
    },
    {
        name: 'Legal',
        link: '',
        children: [
            {
                name: 'Terms of Service',
                link: '/legal/tos',
            },
            {
                name: 'DPA',
                link: '/legal/dpa',
                loggedIn: true,
            },
            {
                name: 'Licenses',
                link: '/legal/licensenotice',
                loggedIn: true,
            },
            {
                name: 'Privacy Policy',
                element: <PolicyButton link={activePolicyLink} color={ButtonColor.BLUE} flat={true} />,
            },
        ],
    },
]

export default bottomMenu
