import { createStyles, Theme, Typography } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import React, { useState } from 'react'

import getMenu from '../../data/bottom-menu'
import { Menu as IMenu } from '../../data/menu'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            display: 'flex',
            flexShrink: 0,
            width: '100%',
            justifyContent: 'space-around',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        menuContainer: {
            margin: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0,
            },
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        small: {
            fontSize: '0.8rem',
            color: theme.palette.grey[300],
        },
        menuLink: {
            color: theme.palette.primary.contrastText,
            display: 'block',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        menuHeader: {
            color: theme.palette.primary.contrastText,
            fontSize: '1.2rem',
            marginBottom: theme.spacing(1),
        },
        link: {
            color: theme.palette.primary.contrastText,
            display: 'block',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    })

export interface BottomMenuProps extends WithStyles<typeof styles> {
    isLoggedIn: boolean
    config: {
        activePolicyLink: string
    }
}

const BottomMenu = (props: BottomMenuProps) => {
    const { classes, config, isLoggedIn } = props
    const menu = getMenu(config)
    const renderMenu = (m: IMenu, i: number) => (
        <div key={i} className={classes.menuContainer}>
            <Typography className={classes.menuHeader} variant="subtitle1">
                {m.name}
            </Typography>

            {m.children &&
                m.children.map((c, idx) => {
                    const requiresLogin = c.loggedIn && !isLoggedIn
                    const key = `${m.name}-${idx}`
                    if (!(c.link || c.element)) {
                        return null
                    }
                    if (requiresLogin) {
                        return (
                            <div key={key}>
                                {c.name} <span className={classes.small}>(members only)</span>
                            </div>
                        )
                    }
                    return (
                        <React.Fragment key={key}>
                            {c.link && (
                                <Link className={classes.link} to={c.link}>
                                    {c.name}
                                </Link>
                            )}
                            {c.element}
                        </React.Fragment>
                    )
                })}
        </div>
    )
    /** Start render */
    return <div className={classes.root}>{menu.map(renderMenu)}</div>
}

export default withStyles(styles)(BottomMenu)
